import { FC } from 'react';

import css from './Text.module.scss';

const Text: FC = () => (
  <div className={css.Text}>
    <h1>Лаки Вет</h1>
    <h2>Ветеринарная клиника</h2>
  </div>
);

export default Text;

import { FC } from 'react';

import logo from './logo.png';
import css from './Logo.module.scss';

const Logo: FC = () => (
  <div className={css.Logo}>
    <img className={css.Logo__main} src={logo} alt="logo" />
    {/* <img className={css.Logo__blur} src={logo} alt="" /> */}
  </div>
);

export default Logo;

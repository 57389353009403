import { FC } from 'react';

import Text from 'components/Text';
import Logo from 'components/Logo';
import Cat from 'components/Cat';

import PawsContainer from 'views/PawsContainer';

import css from './Main.module.scss';

const Main: FC = () => (
  <div className={css.Main}>
    <Text />
    <Logo />
    <Cat />
    <PawsContainer />
  </div>
);

export default Main;

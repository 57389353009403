import { FC, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';

import Main from 'views/Main';

import css from './App.module.scss';
import 'react-toastify/dist/ReactToastify.css';

const App: FC = () => {
  useEffect(() => {

  }, []);

  return (
    <div className={css.App}>
      <ToastContainer position="top-right" draggable={false} pauseOnHover theme="light" style={{ whiteSpace: 'pre-line' }} />
      <Main />
    </div>
  );
};

export default App;

import { createRoot } from 'react-dom/client';
import { ConfigProvider } from 'antd';

import ErrorBoundary from 'views/Error';

import 'dayjs/locale/ru';
import locale from 'antd/locale/ru_RU';

import 'normalize.css';
import './Font/index.scss';
import './index.scss';

import App from './App';

const root = createRoot(document.getElementById('lucky_calc') as Element);

root.render(
  <ConfigProvider locale={locale}>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </ConfigProvider>
);

import { FC } from 'react';
import Paws from 'components/Paws';

import css from './PawsContainer.module.scss';

type IPawsContainer = {
  debug?: boolean;
};

const PawsContainer: FC<IPawsContainer> = ({ debug = false }) => (
  <div className={css.PawsContainer}>
    <Paws
      style={{
        bottom: -90 + 'px',
        transform: 'rotate(' + 295 + 'deg)',
      }}
      count={30}
      iterations="infinite"
      delay={0}
      debug={debug}
    />
    <Paws
      style={{
        right: -140 + 'px',
        top: -260 + 'px',
        transform: 'rotate(' + 11 + 'deg)',
      }}
      count={30}
      iterations="infinite"
      delay={15}
      debug={debug}
    />
    <Paws
      style={{
        transform: 'rotate(' + 163 + 'deg)',
        transformOrigin: 'center',
        right: -180 + 'px',
        bottom: 50 + 'px',
      }}
      count={30}
      iterations="infinite"
      delay={30}
      debug={debug}
    />
  </div>
);

export default PawsContainer;

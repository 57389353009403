import { CSSProperties, FC } from 'react';
import cn from 'classnames';

import css from './Paws.module.scss';

import paw from './paw.svg';

type IPaws = {
  count?: number;
  iterations?: 'infinite' | number,
  delay?: number,
  style?: CSSProperties,
  debug?:boolean,
};

const Paws: FC<IPaws> = ({ count = 10, iterations = 1, delay = 0, style = {}, debug = false }) => {
  const paws = [];

  const pawStyle: CSSProperties = {
    animationDuration: (count + 1) * 2 + 's',
    animationIterationCount: iterations,
  };

  if (debug) pawStyle.animationName = 'unset';

  count -= 1;

  for (let i = 0; i <= count; i += 1) {
    const paw1Style: CSSProperties = {
      left: (i * 90 + 'px'),
      animationDelay: (2 * i) + delay + 's',
      top: 85 + 'px',
    };

    const paw2Style: CSSProperties = {
      left: (i * 90 + 45 + 'px'),
      animationDelay: (2 * i + 1) + delay + 's',
      top: 0,
    };

    paws.push(
      <img src={paw} alt="" className={css.Paws__paw} style={{ ...pawStyle, ...paw1Style }} key={i * 2} />
    );

    paws.push(
      <img src={paw} alt="" className={css.Paws__paw} style={{ ...pawStyle, ...paw2Style }} key={i * 2 - 1} />
    );
  }

  return (
    <div
      className={css.Paws}
      style={{
        width: (count + 1) * 90 + 'px',
        backgroundColor: debug ? 'green' : 'transparent',
        ...style
      }}
    >
      {paws}
    </div>
  );
};

export default Paws;

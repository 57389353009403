import { Component, ReactNode } from 'react';

import { Button, Result } from 'antd';

import css from './Error.module.scss';

type ErrorBoundaryProps = {
  children: ReactNode;
};
type ErrorBoundaryState = {
  hasError: boolean;
};

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    const extra = (
      <div>
        <Button
          type="primary"
          onClick={() => { window.location.reload(); }}
          className={css.Error__refresh}
        >
          Обновить страницу
        </Button>
      </div>
    );

    if (hasError) {
      return (
        <Result
          status="500"
          title="Произошла ошибка"
          subTitle="Вот так бывает в жизни..."
          extra={extra}
          className={css.Error}
        />
      );
    }

    return children;
  }
}

export default ErrorBoundary;
